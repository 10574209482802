import React from 'react';

import { Routes, Route } from "react-router-dom";
import './index.css';
// import Hero from './components/pages/Hero';
import Home from './components/pages/Home'; 
import Nosotros from './components/pages/Nosotros'; 
import Contacto from './components/pages/Contacto'; 
import Asistencia from './components/pages/Asistencia'; 
import Conversiones from './components/pages/Conversiones';
import ScrollToTop from './components/base/ScrollToTop'; 



function App() {
  return (

    <>
      <ScrollToTop /> {/* Agrega el componente aquí */}
      <div>
          <Routes>
              {/* <Route path="/" element={<Hero />} /> */}
              <Route path="/" element={<Home />} />
              <Route path="/nosotros" element={<Nosotros />} />
              <Route path="/contacto" element={<Contacto />} />
              <Route path="/asistencia" element={<Asistencia />} />
              <Route path="/conversiones" element={<Conversiones />} />
          </Routes>
      </div>
    </>
  );
}

export default App;
