import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logoER-YB.png';
import marco from '../../assets/img/marco.png';
import '../css/stylesHeader.css';

const menuItems = [
  { path: '/', label: 'Inicio' },
  { path: '/nosotros', label: 'Nosotros' },
  {
    label: 'Servicios',
    path: '/servicios',
    dropdown: true,
    items: [
      { path: '/conversiones', label: 'Conversiones' },
      { path: '/asistencia', label: 'Asistencia/Ventas' },
    ]
  },
  { path: '/contacto', label: 'Contacto' }
];

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && isOpen) {
        setIsOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen]);

  return (
    <header className={`p-4 ${isOpen ? 'opaque-bg' : 'bg-transparent'}`}>
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center max-w-7xl">
        <div className="flex justify-between items-center w-full">
          <Link to="/">
            <img className="logo h-12 transition-transform hover:scale-110" src={logo} alt="Logo" />
          </Link>
          <button className="menu menu-sm lg:hidden shadow bg-base-100 rounded-box" onClick={() => setIsOpen(!isOpen)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
            </svg>
          </button>
        </div>
        <nav className={`flex flex-col lg:flex-row w-full lg:w-auto mt-2 lg:mt-0 ${isOpen ? 'block' : 'hidden'} lg:flex lg:space-x-4`}>
          <ul className="menu rounded-box w-full lg:w-max flex flex-col lg:flex-row">
            {menuItems.map((item, index) => (
              <li key={item.label} className="menu-item relative">
                {item.dropdown ? (
                  <div className="relative group">
                    <button
                      className="text-neutral-300 hover:text-verde cursor-pointer text-base hover:bg-transparent relative flex items-center"
                      onClick={() => setOpenDropdown(openDropdown === index ? null : index)}>
                      {item.label}
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 12a1 1 0 0 1-.707-.293l-4-4a1 1 0 0 1 1.414-1.414L10 9.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4A1 1 0 0 1 10 12z" clipRule="evenodd" />
                      </svg>
                    </button>
                    {openDropdown === index && (
                      <ul className="menu dropdown-content bg-base-100 rounded-box shadow  top-full  lg:left-auto lg:right-0">
                        {item.items.map(subItem => (
                          <li key={subItem.label}>
                            <Link
                              to={subItem.path}
                              className="block px-4 py-2 text-neutral-300 hover:text-verde hover:bg-transparent">
                              {subItem.label}
                            </Link>
                          </li>
                        ))}
                      </ul>)}
                  </div>
                ) : (
                  <Link to={item.path} className="text-neutral-300 hover:text-verde text-base hover:bg-transparent relative flex items-center justify-center h-12">
                    <img src={marco} alt="" className=" absolute h-full w-full object-cover hide-responsive" />
                    <span className="relative">{item.label}</span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
