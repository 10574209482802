import React from 'react';
import '../css/stylesHome.css';

/*const WhatsAppButton = () => {*/
const WhatsAppButton = ({ showScrollBtn, isButtonAbsolute }) => {
  if (!showScrollBtn) {
    return null;
  }




    return (
      /*<div className="wsp-button fixed ">*/
      <div 
        className={`wsp-button bounce ${showScrollBtn ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-opacity duration-500`}
        style={{
          position: isButtonAbsolute ? 'absolute' : 'fixed',
          bottom: isButtonAbsolute ? '20px' : '70px',
          right: '13px',
        }}>
        <a 
          href="https://wa.me/+56984058436" 
          target="_blank" 
          rel="noopener noreferrer"
          className="bg-verde hover:bg-amarillo text-white rounded-full shadow-lg p-4 flex items-center justify-center transition-transform duration-300 hover:scale-110"
          style={{ width: '54px', height: '54px'}}
        >
          <i className="fab fa-whatsapp h-9.5 w-7 text-gray-900" style={{ fontSize: '38px'}}></i>
        </a>
      </div>
    );
  }
  
  export default WhatsAppButton;
