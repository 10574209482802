import React from 'react';
import Header from '../base/Header';
import Footer from '../base/Footer';
import asesoria from "../../assets/img/asesoria.jpg";
import venta from "../../assets/img/venta.jpg";
import mantenimiento2 from "../../assets/img/mantenimiento2.jpg";
import { Link } from 'react-router-dom';


import '../css/stylesAsistencia.css';



function Servicios() {

  const servicesList = [
    /*{ title: 'Conversión de Motores', description: 'Transformación de motos a combustión a eléctricas y kits de conversión.', imagen: conversion2, link: '/conversiones'  },*/
    { title: 'Asesoría y Consultoría', description: 'Asesoramiento personalizado y capacitación sobre transformaciones eléctricas.', imagen: asesoria },
    { title: 'Mantenimiento y Reparación', description: 'Mantenimiento especializado para motos eléctricas y venta de accesorios.', imagen: mantenimiento2 },
    { title: 'Venta de Motos Eléctricas', description: 'Modelos 100% eléctricos y motos ya convertidas.', imagen: venta },

  ];

  return (
    <>
    <div>
      <Header />
    </div>
    <div className="bg-fondo p-10 text-white max-w-6xl mx-auto">
      <h1 className='text-center t-100 text-neutral-100 font-bold text-4xl pb-8' data-aos='fade-right'><span className='font-bold text-3xl'>Asistencia y </span><span className='text-amarillo'> Ventas</span></h1>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {servicesList.map((service, index) => (
          <div key={index} className="p-6 bg-neutral-800 rounded-lg shadow-lg border-verde ">
            <h3 className="text-xl font-bold mb-4 text-verde text-center">
              {service.link ? (
                <a href={service.link}>{service.title}</a>
              ) : (
                service.title
              )}
            </h3>
            <img src={service.imagen} alt="Album" className="rounded-lg w-72 mx-auto mb-6" data-aos="zoom-in" />

            <p className='text-center text-neutral-200'>{service.description}</p>

          </div>
        ))}
      </div>

      {/*Botón Cotizar Laptop*/}
      <div className="justify-center flex flex-col md:flex-row items-center my-20 gap-4">
        <a href="https://wa.me/56984058436" target="_blank" rel="noopener noreferrer">        
          <button className="btn flex items-center text-xl border-1 border-verde text-verde bg-transparent hover:bg-verde hover:text-negro font-bold px-4 rounded-sm">
            <i className="fab fa-whatsapp icono-contacto m-0"></i>
            Contáctanos
          </button>
        </a>
        <Link to="/contacto">
          <button className="btn flex items-center justify-center text-xl border-1 border-verde text-verde bg-transparent hover:bg-verde hover:text-negro font-bold px-4 rounded-sm">
            <i className="far fa-envelope icono-contacto m-0"></i>
            Escríbenos
          </button>
        </Link>
      </div>

    </div>
    
    <div>
      <Footer />
    </div>    
    </>
  )
}

export default Servicios
